import { useRecoilState } from "recoil"
import { authTypeState } from "../atoms/application"
import { useEffect, useState } from "react"
import axios from "axios"
import { error } from "../logger"
import { loggedState } from "../atoms/infrastructure"
import { useMsal } from "@azure/msal-react"
import { CacheLookupPolicy } from "@azure/msal-browser"
import { msalState } from "../atoms/msal"

export interface IAxios {
  /**
   * Value, if is error dialog
   */
  openErrorDialog: boolean
  /**
   * Set value, for opne dialog
   * @param open
   */
  setOpenErrorDialog: (open: boolean) => void
}

export const useAxios = (): IAxios => {
  const { instance, accounts, inProgress } = useMsal()
  const [msalConfig] = useRecoilState(msalState)
  const [openErrorDialog, setOpenErrorDialog] = useState(false)
  const [authType] = useRecoilState(authTypeState)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setLogged] = useRecoilState(loggedState)
  // Register/Unregister bearer
  useEffect(() => {
    if (accounts.length > 0) {
      const id = axios.interceptors.request.use(async (request) => {
        if (request.headers) {
          const loginRequest = {
            scopes: [msalConfig!.REACT_AUTH_SCOPE],
          }
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
            forceRefresh: false,
            cacheLookupPolicy: CacheLookupPolicy.Default,
          })
          request.headers["Authorization"] = "Bearer " + response.accessToken
        }
        return request
      })
    }
  }, [accounts, instance, msalConfig])

  useEffect(() => {
    // catch all requests
    axios.interceptors.request.use((request) => {
      if (request.headers) {
        request.headers["Cache-Control"] = "no-cache"
      }
      return request
    })
    // catch all responses
    axios.interceptors.response.use(
      (response) => {
        return response
      },
      (errorResponse) => {
        if (errorResponse?.response?.status === 401) {
          setLogged(false)
        }
        if (errorResponse?.response?.status >= 500) {
          setOpenErrorDialog(true)
        }
        error(errorResponse)
        // return error
      }
    )
  }, [setLogged])

  return {
    openErrorDialog,
    setOpenErrorDialog,
  }
}
