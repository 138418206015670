import { Theme } from "@mui/material"
import { createStyles, makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: "hidden",
      width: "100%",
    },
    container: {
      width: "100% !important",
      maxWidth: "100% !important",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: "40px",
      "& > *": {
        marginBottom: "24px",
        maxWidth: "100%",
      },
      "& > *:first-child": {
        marginTop: "24px",
      },
    },
    applicationBoxIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "84px",
      height: "84px",
    },
    applicationIcon: {
      width: "52px",
      height: "52px",
      margin: "4px",
      fill: theme.palette.primary.main,
    },
    newApplicationButton: {},
  })
)

export default useStyles
