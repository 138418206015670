import {
  CasesApi,
  // @ts-ignore
  ClientsApiFactory,
  Configuration,
} from "../generated-sources/sp"
import { useRecoilState } from "recoil"
import { trackingState } from "../atoms/infrastructure"
import { error } from "../logger"
import { BACKEND_URL } from "../utils/const"
import { listApplicationsState } from "../atoms/application"
import { useData } from "./useData"

const apiCases = new CasesApi(
  new Configuration({
    basePath: BACKEND_URL,
  })
)

interface IListApplication {
  search: () => void
}

// create REST api
const api = ClientsApiFactory(
  new Configuration({
    basePath: BACKEND_URL,
  })
)

const checkInProcess = async (caseId, trackingId) => {
  const response = await apiCases.applicationResult("", "", caseId, trackingId)
  return response.data
}

export const useListApplication = (): IListApplication => {
  const [trackingId] = useRecoilState(trackingState)
  const [, setListApplications] = useRecoilState(listApplicationsState)
  const { setRow } = useData()

  const search = async () => {
    const response = await api.listAdelRequests("", trackingId)
    if (response.data) {
      // If data contains IN_PROCESS change it to CHECK_IN_PROCESS
      // We load new status from state machine
      let data: any = []
      // Check if are data in response defined
      if (response.data) {
        data = response.data.map((item) => {
          if (item.status === "IN_PROCESS") {
            item.status = "CHECK_IN_PROCESS"
          }
          return item
        })
      }
      setListApplications(data)
      // Enumerate through all rows and check if status is CHECK_IN_PROCESS
      // then load new data
      let newData = [...data]
      for (const row of data) {
        if (row.status === "CHECK_IN_PROCESS") {
          const result = await checkInProcess(row.caseId, trackingId)
          // Create new data array where update row with new status
          newData = newData.map((item) => {
            if (item.caseId === row.caseId) {
              return {
                ...item,
                status: result.status,
              }
            }
            return item
          })
        }
      }
      setListApplications([...newData])
    } else {
      error("Data from backend are not returned")
    }
  }
  return {
    search,
  }
}
