import { useStylesEmailSend } from "./index"
import { ReactComponent as CheckmarkIcon } from "./../../../assets/icons/control/control-checkmark.svg"
import {Button, DialogTitle, Link} from "@mui/material"
import Typography from "@mui/material/Typography"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useRecoilState } from "recoil"
import { formNewApplicationState, idState } from "../../../atoms/application"
import { useEmailCreateCase } from "../../../hooks/useEmailCreateCase";
import { createClient } from "./utils"

export interface IEmailSend {
  handleDialogClose: () => void
}

export const EmailSend: React.FC<IEmailSend> = ({ handleDialogClose }) => {
  const [idApplication, setIdApplication] = useRecoilState(idState)
  const classes = useStylesEmailSend()
  const [formNewApplication] = useRecoilState(formNewApplicationState)
  const [t] = useTranslation()
  const { createCase: emailCreateCase, sendEmail } = useEmailCreateCase()

  const resend = useCallback(async () => {
    const dataToApplication = { ...formNewApplication }
    const requestId: number | null = await emailCreateCase(
      createClient(dataToApplication),
      idApplication
    )
    if (requestId) {
      setIdApplication(requestId)
      await sendEmail(requestId, dataToApplication)
    }
  }, [
    formNewApplication,
    idApplication,
    emailCreateCase,
    sendEmail,
    setIdApplication,
  ])

  return (
    <div className={classes.root}>
      <p>
        <CheckmarkIcon className={classes.checkmarkIcon} />
      </p>
      <DialogTitle>
        <Typography component="h2" variant="h2" color="primary">
          {t("EMAIL ODESLÁN")}
        </Typography>
      </DialogTitle>
      <p>
        {t(`Žádost o ověření odeslána žadateli.`)}
        <br />
        {t(`Můžete se vrátit zpět na hlavní stránku.`)}
      </p>
      <p>
        {t(`Email nedorazil?`)}
        <Link
          href="#"
          className={classes.linkSendAgain}
          onClick={resend}
          color="primary"
        >
          {t("Poslat znovu")}
        </Link>
      </p>
      <p>
        <Button
          id="fillPhone"
          color="primary"
          variant="contained"
          disableElevation={true}
          onClick={handleDialogClose}
        >
          {t("zavřít")}
        </Button>
      </p>
    </div>
  )
}
