import { Theme } from "@mui/material"
import { createStyles, makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    backdrop: {
      backgroundColor: theme.palette.primary.main,
      position: "fixed",
      opacity: "50% !important",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 9998,
    },
    progress: {
      left: "calc(50% - 20px)",
      top: "calc(50% - 20px)",
      zIndex: 9999,
      position: "fixed",
      color: "white",
    },
  })
})

export default useStyles
