import React, { useCallback } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"

export interface ICloseDialog {
  open: boolean
  setOpen: (open: boolean) => void
  /**
   * Function is called when dialog is closed
   */
  handleDialogIsClosed: () => void
}

const CloseDialog: React.FC<ICloseDialog> = ({
  open,
  setOpen,
  handleDialogIsClosed,
}) => {
  const [t] = useTranslation()

  const handleYesClick = useCallback(() => {
    handleDialogIsClosed()
    setOpen(false)
  }, [handleDialogIsClosed, setOpen])

  const handleNoClick = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Typography component="h2" variant="h2" color="primary" noWrap>
          {t("Ukončení žádosti")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>
            {t("Opravdu si přejete ukončit vyplňování žádosti?")}
          </Typography>
          <Typography>{t("Zadaná data budou ztracena.")}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          disableElevation={true}
          onClick={handleYesClick}
        >
          {t("ano")}
        </Button>
        <Button
          disableElevation={true}
          color="primary"
          variant="contained"
          onClick={handleNoClick}
        >
          {t("ne")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CloseDialog
