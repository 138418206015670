import { Theme } from "@mui/material"
import { createStyles, makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      "& .MuiPaper-root": {
        maxWidth: "760px",
      },
      "& .MuiDialogTitle-root": {
        margin: "0 auto",
      },
    },
    iconClose: {
      position: "absolute",
      right: "18px",
      top: "18px",
    },
    step2DialogContent: {
      width: "unset !important",
    },
    dialogDescription: {
      marginBottom: "40px",
    },
  })
})

export default useStyles
