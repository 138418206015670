import React from "react"
import { IRow } from "./IRow"
import { IconButton, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { ReactComponent as DocumentPdfIcon } from "../../../assets/icons/content/document-pdf.svg"
import { useDownloadPdf } from "../../../hooks/useDownloadPdf"

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      width: "48px",
      height: "48px",
    },
    icon: {
      fontSize: "24px",
      height: "24px",
      fill: theme.palette.primary.main,
    },
  }
})

export const DownloadPdf: React.FC<{
  rowData: IRow
}> = ({ rowData }) => {
  const classes = useStyles()
  const { callDownloadPdf } = useDownloadPdf()

  const handleButtonClick = React.useCallback(async () => {
    await callDownloadPdf(rowData.idApplication)
  }, [callDownloadPdf, rowData.idApplication])

  return (
    <IconButton className={classes.root} onClick={handleButtonClick}>
      <DocumentPdfIcon className={classes.icon} />
    </IconButton>
  )
}

export default DownloadPdf
