import { useMsal } from "@azure/msal-react"
import { useEffect } from "react"
import { useRecoilState } from "recoil"
import { msalState } from "../atoms/msal"

const prolong = (accounts, instance, msalConfig) => {
  if (accounts.length > 0) {
    const loginRequest = {
      scopes: [msalConfig!.REACT_AUTH_SCOPE],
    }
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        forceRefresh: true,
      })
      .then((response) => {
        console.log("Prolonged access token")
      })
      // failed prolongation
      .catch((error) => {
        console.log("Prolongation failed")
      })
  }
}

/**
 * Make access token and refresh token working longer
 */
export const useAADProlong = () => {
  const { instance, accounts, inProgress } = useMsal()
  const [msalConfig] = useRecoilState(msalState)
  useEffect(() => {
    // Call prolong every 15 minutes
    const interval = setInterval(() => {
      prolong(accounts, instance, msalConfig)
    }, 15 * 60 * 1000)
  }, [accounts, instance, msalConfig])
}
