import React from "react"
import { useRecoilState } from "recoil"
import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import { userState } from "../../../atoms/usermanagement"
import { Link, Typography } from "@mui/material"
import { ReactComponent as UserIcon } from "../../../assets/icons/content/user.svg"
import { ReactComponent as LogoutIcon } from "../../../assets/icons/control/control-logout.svg"
import useStyles from "./user.styles"
import clsx from "clsx"

const User: React.FC = () => {
  const [user] = useRecoilState(userState)
  const isAuthenticated = useIsAuthenticated()

  const { instance } = useMsal()
  const classes = useStyles()

  if (!isAuthenticated) {
    return null
  }

  return (
    <>
      <div className={classes.root}>
        <Typography variant="body2">
          {user?.name} {user?.surname}
          <UserIcon className={clsx(classes.icon, classes.iconUser)} />
        </Typography>
        <Link
          href="#"
          underline="none"
          className={classes.logoutLink}
          onClick={() => instance.logoutRedirect()}
        >
          <Typography variant="body2">Odhlásit se</Typography>
          <LogoutIcon className={classes.icon} />
        </Link>
      </div>
    </>
  )
}

export default User
