import { IRow } from "./IRow"
import { IconButton, Theme, Tooltip } from "@mui/material"
import { makeStyles } from "@mui/styles"
import Cached from "@mui/icons-material/Cached"
import React, { useCallback, useEffect, useState } from "react"
import { useGetApplicationResult } from "../../../hooks/useGetApplicationResult"
import { TooltipStatus } from "./tooltipStatus"

const useStyles = makeStyles((theme: Theme) => {
  return {
    icon: {},
    rotateIcon: {
      animation: "$rotate 2s infinite linear",
    },
    "@keyframes rotate": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
  }
})

export const ReloadApplication: React.FC<{
  rowData: IRow
  setUserChangedStatus: (change: Array<{ id?: number; status: string }>) => void
}> = ({ rowData, setUserChangedStatus }) => {
  const classes = useStyles()
  const { callApplicationResult, applicationResult } = useGetApplicationResult()
  const [rotate, setRotate] = useState(false)

  const handleClick = useCallback(() => {
    setRotate(true)
    callApplicationResult(rowData.idApplication?.toString())
  }, [callApplicationResult, rowData.idApplication, setRotate])

  useEffect(() => {
    const status = applicationResult?.status
    setRotate(false)
    if (status) {
      setUserChangedStatus([{ id: rowData.idApplication, status }])
    }
  }, [applicationResult, rowData.idApplication, setUserChangedStatus])

  // If is status done, do not render reload button
  if (rowData.statusCode === "DONE") {
    return null
  }

  return (
    <TooltipStatus status={rowData.status}>
      <IconButton
        className={rotate ? classes.rotateIcon : ""}
        color="primary"
        onClick={handleClick}
      >
        <Cached />
      </IconButton>
    </TooltipStatus>
  )
}
