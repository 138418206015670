import { atom } from "recoil"
import { sessionStorageEffect } from "./index"
// @ts-ignore
import { AdelRequest } from "../generated-sources/sp"

enum Keys {
  AUTH_TYPE = "AUTH_TYPE",
  ID = "ID",
  MODAL_DIALOG_OPEN = "MODAL_DIALOG_OPEN",
  MODAL_DIALOG_PAGE = "MODAL_DIALOG_PAGE",
  FORM_NEW_APPLICATION = "FORM_NEW_APPLICATION",
  LIST_APPLICATIONS = "LIST_APPLICATIONS",
}

export const authTypeState = atom({
  key: Keys.AUTH_TYPE,
  default: null,
  effects_UNSTABLE: [
    sessionStorageEffect<"byEmail" | "aad" | null>(Keys.AUTH_TYPE),
  ],
})

export const idState = atom({
  key: Keys.ID,
  default: null,
  effects_UNSTABLE: [sessionStorageEffect<number | null>(Keys.ID)],
})

export const modalDialogOpenState = atom({
  key: Keys.MODAL_DIALOG_OPEN,
  default: false,
  effects_UNSTABLE: [sessionStorageEffect<boolean>(Keys.MODAL_DIALOG_OPEN)],
})

export const modalDialogPageState = atom({
  key: Keys.MODAL_DIALOG_PAGE,
  default: 0,
  effects_UNSTABLE: [sessionStorageEffect<number>(Keys.MODAL_DIALOG_PAGE)],
})

export interface IFormNewApplication {
  name: string
  surname: string
  company: string
  phone: string
  email: string
}

export const defaultFormNewApplication: IFormNewApplication = {
  name: "",
  surname: "",
  company: "",
  phone: "(+420) ",
  email: "",
}

export const formNewApplicationState = atom({
  key: Keys.FORM_NEW_APPLICATION,
  default: defaultFormNewApplication,
  effects_UNSTABLE: [
    sessionStorageEffect<IFormNewApplication>(Keys.FORM_NEW_APPLICATION),
  ],
})

export const listApplicationsState = atom({
  key: Keys.LIST_APPLICATIONS,
  default: [],
  effects_UNSTABLE: [
    sessionStorageEffect<AdelRequest[]>(Keys.LIST_APPLICATIONS),
  ],
})
