import { Theme } from "@mui/material"
import { createStyles, makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    footer: {
      justifyContent: "center",
    },
  })
})

export default useStyles
