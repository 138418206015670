import React from "react"
import Search from "@mui/icons-material/Search"
import ViewColumn from "@mui/icons-material/ViewColumn"
import SaveAlt from "@mui/icons-material/SaveAlt"
import ChevronLeft from "@mui/icons-material/ChevronLeft"
import ChevronRight from "@mui/icons-material/ChevronRight"
import FirstPage from "@mui/icons-material/FirstPage"
import LastPage from "@mui/icons-material/LastPage"
import Add from "@mui/icons-material/Add"
import ArrowDownward from "@mui/icons-material/ArrowDownward"
import Check from "@mui/icons-material/Check"
import FilterList from "@mui/icons-material/FilterList"
import Remove from "@mui/icons-material/Remove"
import Clear from "@mui/icons-material/Clear"
import Edit from "@mui/icons-material/Edit"
import DeleteOutline from "@mui/icons-material/DeleteOutline"
import { Icons } from "@material-table/core"

const icons = {
  Add: React.forwardRef((props, ref) => <Add {...props} ref={ref && null} />),
  SortArrow: React.forwardRef((props, ref) => <ArrowDownward {...props} ref={ref && null} />),
  Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref && null} />),
  ResetSearch: React.forwardRef((props, ref) => <Clear {...props} ref={ref && null} />),
  Delete: React.forwardRef((props, ref) => <DeleteOutline {...props} ref={ref && null} />),
  Check: React.forwardRef((props, ref) => <Check {...props} ref={ref && null} />),
  Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref && null} />),
  Export: React.forwardRef((props, ref) => <SaveAlt {...props} ref={ref && null} />),
  Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref && null} />),
  FirstPage: React.forwardRef((props, ref) => <FirstPage {...props} ref={ref && null} />),
  LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref && null} />),
  NextPage: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref && null} />),
  PreviousPage: React.forwardRef((props, ref) => <ChevronLeft {...props} ref={ref && null} />),
  Search: React.forwardRef((props, ref) => <Search {...props} ref={ref && null} />),
  ThirdStateCheck: React.forwardRef((props, ref) => <Remove {...props} ref={ref && null} />),
  ViewColumn: React.forwardRef((props, ref) => <ViewColumn {...props} ref={ref && null} />),
  DetailPanel: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref && null} />),
} as Icons<any>

export default icons
