import React from "react"
import { error } from "../../logger"

interface IState {
  hasError: boolean
}

class ErrorBoundaries extends React.PureComponent<
  React.ComponentProps<any>,
  IState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true }
  }

  componentDidCatch(inError: Error, errorInfo: React.ErrorInfo) {
    error(inError.message)
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>
    }
    return this.props.children
  }
}

export default ErrorBoundaries
