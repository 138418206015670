/* tslint:disable */
/* eslint-disable */
/**
 * serviceportalserverapi
 * API contains services for Service Portal FE.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * detail information about address
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * name of street
     * @type {string}
     * @memberof Address
     */
    streetName?: string;
    /**
     * The house number
     * @type {string}
     * @memberof Address
     */
    houseNumber?: string;
    /**
     * The land registry number
     * @type {string}
     * @memberof Address
     */
    landRegistryNumber?: string;
    /**
     * name of city
     * @type {string}
     * @memberof Address
     */
    city?: string;
    /**
     * post code
     * @type {string}
     * @memberof Address
     */
    postCode?: string;
    /**
     * The country code in ISO 3166-1 alpha-3 format (CZE, SVK, ...)
     * @type {string}
     * @memberof Address
     */
    country?: string;
}
/**
 * Clients Adel request
 * @export
 * @interface AdelRequest
 */
export interface AdelRequest {
    /**
     * 
     * @type {number}
     * @memberof AdelRequest
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AdelRequest
     */
    caseId?: number;
    /**
     * 
     * @type {Client}
     * @memberof AdelRequest
     */
    client?: Client;
    /**
     * 
     * @type {Employee}
     * @memberof AdelRequest
     */
    employee?: Employee;
    /**
     * status of the given request - can be IN_PROCESS|DONE|FAILED|null
     * @type {string}
     * @memberof AdelRequest
     */
    status?: string;
    /**
     * Date of creation
     * @type {string}
     * @memberof AdelRequest
     */
    createdAt?: string;
    /**
     * branch office name of the given employee
     * @type {string}
     * @memberof AdelRequest
     */
    branchOffice?: string;
}
/**
 * Response after successful download of application
 * @export
 * @interface ApplicationResultResponse
 */
export interface ApplicationResultResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationResultResponse
     */
    status?: ApplicationResultResponseStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ApplicationResultResponseStatusEnum {
    Done = 'DONE',
    Error = 'ERROR',
    ErrorShortPeriod = 'ERROR_SHORT_PERIOD',
    InProcess = 'IN_PROCESS'
}

/**
 * 
 * @export
 * @interface BranchOffice
 */
export interface BranchOffice {
    /**
     * identification of the given entity
     * @type {number}
     * @memberof BranchOffice
     */
    id?: number;
    /**
     * official name of the given branch office
     * @type {string}
     * @memberof BranchOffice
     */
    name?: string;
}
/**
 * Overview of case for a payment
 * @export
 * @interface CaseOverview
 */
export interface CaseOverview {
    /**
     * Id of case
     * @type {string}
     * @memberof CaseOverview
     */
    id?: string;
    /**
     * External id of case
     * @type {string}
     * @memberof CaseOverview
     */
    externalCaseId?: string;
    /**
     * Status of case
     * @type {string}
     * @memberof CaseOverview
     */
    status?: string;
    /**
     * Date when was case created
     * @type {string}
     * @memberof CaseOverview
     */
    createdAt?: string;
    /**
     * Name of user who created the case
     * @type {string}
     * @memberof CaseOverview
     */
    name?: string;
    /**
     * Surname of user who created the case
     * @type {string}
     * @memberof CaseOverview
     */
    surname?: string;
    /**
     * Name of service provider
     * @type {string}
     * @memberof CaseOverview
     */
    serviceProvider?: string;
    /**
     * Id of case in Service Portal
     * @type {number}
     * @memberof CaseOverview
     */
    idCaseServicePortal?: number;
    /**
     * Date of creation at Service Portal
     * @type {string}
     * @memberof CaseOverview
     */
    createdAtServicePortal?: string;
    /**
     * Email of a dealer
     * @type {string}
     * @memberof CaseOverview
     */
    emailEmployee?: string;
    /**
     * Account number in case
     * @type {string}
     * @memberof CaseOverview
     */
    accountNumber?: string;
    /**
     * Name of bank acocunt owner
     * @type {string}
     * @memberof CaseOverview
     */
    ownerBankAccount?: string;
}
/**
 * detail information about client
 * @export
 * @interface Client
 */
export interface Client {
    /**
     * Name of the given user
     * @type {string}
     * @memberof Client
     */
    name: string;
    /**
     * Surname of the given user
     * @type {string}
     * @memberof Client
     */
    surname: string;
    /**
     * Personal identification number of the given user
     * @type {string}
     * @memberof Client
     */
    personalIdentNumber?: string;
    /**
     * Phone number of the given user
     * @type {string}
     * @memberof Client
     */
    phone: string;
    /**
     * Email address of the given user
     * @type {string}
     * @memberof Client
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    sex?: ClientSexEnum;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    company?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ClientSexEnum {
    Male = 'MALE',
    Female = 'FEMALE'
}

/**
 * 
 * @export
 * @interface ClientContactInfo
 */
export interface ClientContactInfo {
    /**
     * 
     * @type {string}
     * @memberof ClientContactInfo
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientContactInfo
     */
    phone?: string;
}
/**
 * 
 * @export
 * @interface ConvertDocRequest
 */
export interface ConvertDocRequest {
    /**
     * 
     * @type {any}
     * @memberof ConvertDocRequest
     */
    content?: any;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ConvertDocRequest
     */
    replaceMap?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface ConvertDocResponse
 */
export interface ConvertDocResponse {
    /**
     * 
     * @type {any}
     * @memberof ConvertDocResponse
     */
    content?: any;
}
/**
 * 
 * @export
 * @interface Employee
 */
export interface Employee {
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    surname?: string;
}
/**
 * Object with identification of the given clients request
 * @export
 * @interface GetConfirmationDocReq
 */
export interface GetConfirmationDocReq {
    /**
     * 
     * @type {number}
     * @memberof GetConfirmationDocReq
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface GetConfirmationDocRes
 */
export interface GetConfirmationDocRes {
    /**
     * 
     * @type {any}
     * @memberof GetConfirmationDocRes
     */
    content?: any;
}
/**
 * 
 * @export
 * @interface LoginReq
 */
export interface LoginReq {
    /**
     * 
     * @type {string}
     * @memberof LoginReq
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginReq
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface LoginRes
 */
export interface LoginRes {
    /**
     * 
     * @type {string}
     * @memberof LoginRes
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRes
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRes
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRes
     */
    email?: string;
}
/**
 * Attributes to next processing of Adel request
 * @export
 * @interface ProcessAdelRequest
 */
export interface ProcessAdelRequest {
    /**
     * 
     * @type {number}
     * @memberof ProcessAdelRequest
     */
    requestId?: number;
    /**
     * 
     * @type {ClientContactInfo}
     * @memberof ProcessAdelRequest
     */
    clientContactInfo?: ClientContactInfo;
    /**
     * 
     * @type {string}
     * @memberof ProcessAdelRequest
     */
    confirmationType?: ProcessAdelRequestConfirmationTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ProcessAdelRequestConfirmationTypeEnum {
    Mobile = 'MOBILE',
    Laptop = 'LAPTOP'
}

/**
 * Object with url
 * @export
 * @interface ProcessAdelResponse
 */
export interface ProcessAdelResponse {
    /**
     * 
     * @type {string}
     * @memberof ProcessAdelResponse
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface RoleRight
 */
export interface RoleRight {
    /**
     * 
     * @type {string}
     * @memberof RoleRight
     */
    name?: RoleRightNameEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RoleRightNameEnum {
    AllRequests = 'ALL_REQUESTS',
    BranchOfficeRequests = 'BRANCH_OFFICE_REQUESTS',
    OwnRequests = 'OWN_REQUESTS'
}

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    surname?: string;
}

/**
 * CasesApi - axios parameter creator
 * @export
 */
export const CasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get result of application
         * @param {string} authorization Authorization token
         * @param {string} xEmployeeId Identification of the employee
         * @param {string} caseId The case id
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationResult: async (authorization: string, xEmployeeId: string, caseId: string, xTrackingId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling applicationResult.');
            }
            // verify required parameter 'xEmployeeId' is not null or undefined
            if (xEmployeeId === null || xEmployeeId === undefined) {
                throw new RequiredError('xEmployeeId','Required parameter xEmployeeId was null or undefined when calling applicationResult.');
            }
            // verify required parameter 'caseId' is not null or undefined
            if (caseId === null || caseId === undefined) {
                throw new RequiredError('caseId','Required parameter caseId was null or undefined when calling applicationResult.');
            }
            const localVarPath = `/clients/application-result/{caseId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sp-token required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("sp-token", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (xTrackingId !== undefined && xTrackingId !== null) {
                localVarHeaderParameter['X-TrackingId'] = String(xTrackingId);
            }

            if (xEmployeeId !== undefined && xEmployeeId !== null) {
                localVarHeaderParameter['X-EmployeeId'] = String(xEmployeeId);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all cases from Adel and Service Portal
         * @param {number} start 
         * @param {number} end 
         * @param {string} order 
         * @param {string} sort 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCaseOverview: async (start: number, end: number, order: string, sort: string, dateFrom: string, dateTo: string, status?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start','Required parameter start was null or undefined when calling getCaseOverview.');
            }
            // verify required parameter 'end' is not null or undefined
            if (end === null || end === undefined) {
                throw new RequiredError('end','Required parameter end was null or undefined when calling getCaseOverview.');
            }
            // verify required parameter 'order' is not null or undefined
            if (order === null || order === undefined) {
                throw new RequiredError('order','Required parameter order was null or undefined when calling getCaseOverview.');
            }
            // verify required parameter 'sort' is not null or undefined
            if (sort === null || sort === undefined) {
                throw new RequiredError('sort','Required parameter sort was null or undefined when calling getCaseOverview.');
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError('dateFrom','Required parameter dateFrom was null or undefined when calling getCaseOverview.');
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError('dateTo','Required parameter dateTo was null or undefined when calling getCaseOverview.');
            }
            const localVarPath = `/clients/case-overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication admin-test required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("admin-test", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['_start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['_end'] = end;
            }

            if (order !== undefined) {
                localVarQueryParameter['_order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['_sort'] = sort;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = dateTo;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CasesApi - functional programming interface
 * @export
 */
export const CasesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get result of application
         * @param {string} authorization Authorization token
         * @param {string} xEmployeeId Identification of the employee
         * @param {string} caseId The case id
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationResult(authorization: string, xEmployeeId: string, caseId: string, xTrackingId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResultResponse>> {
            const localVarAxiosArgs = await CasesApiAxiosParamCreator(configuration).applicationResult(authorization, xEmployeeId, caseId, xTrackingId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets all cases from Adel and Service Portal
         * @param {number} start 
         * @param {number} end 
         * @param {string} order 
         * @param {string} sort 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCaseOverview(start: number, end: number, order: string, sort: string, dateFrom: string, dateTo: string, status?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CaseOverview>>> {
            const localVarAxiosArgs = await CasesApiAxiosParamCreator(configuration).getCaseOverview(start, end, order, sort, dateFrom, dateTo, status, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CasesApi - factory interface
 * @export
 */
export const CasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get result of application
         * @param {string} authorization Authorization token
         * @param {string} xEmployeeId Identification of the employee
         * @param {string} caseId The case id
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationResult(authorization: string, xEmployeeId: string, caseId: string, xTrackingId?: string, options?: any): AxiosPromise<ApplicationResultResponse> {
            return CasesApiFp(configuration).applicationResult(authorization, xEmployeeId, caseId, xTrackingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all cases from Adel and Service Portal
         * @param {number} start 
         * @param {number} end 
         * @param {string} order 
         * @param {string} sort 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCaseOverview(start: number, end: number, order: string, sort: string, dateFrom: string, dateTo: string, status?: string, options?: any): AxiosPromise<Array<CaseOverview>> {
            return CasesApiFp(configuration).getCaseOverview(start, end, order, sort, dateFrom, dateTo, status, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CasesApi - object-oriented interface
 * @export
 * @class CasesApi
 * @extends {BaseAPI}
 */
export class CasesApi extends BaseAPI {
    /**
     * 
     * @summary Get result of application
     * @param {string} authorization Authorization token
     * @param {string} xEmployeeId Identification of the employee
     * @param {string} caseId The case id
     * @param {string} [xTrackingId] Tracking ID for logging
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CasesApi
     */
    public applicationResult(authorization: string, xEmployeeId: string, caseId: string, xTrackingId?: string, options?: any) {
        return CasesApiFp(this.configuration).applicationResult(authorization, xEmployeeId, caseId, xTrackingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all cases from Adel and Service Portal
     * @param {number} start 
     * @param {number} end 
     * @param {string} order 
     * @param {string} sort 
     * @param {string} dateFrom 
     * @param {string} dateTo 
     * @param {string} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CasesApi
     */
    public getCaseOverview(start: number, end: number, order: string, sort: string, dateFrom: string, dateTo: string, status?: string, options?: any) {
        return CasesApiFp(this.configuration).getCaseOverview(start, end, order, sort, dateFrom, dateTo, status, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientsApi - axios parameter creator
 * @export
 */
export const ClientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates new clients Adel request
         * @param {string} authorization Authorization token
         * @param {string} xEmployeeId Identification of the employee
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {AdelRequest} [adelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdelRequest: async (authorization: string, xEmployeeId: string, xTrackingId?: string, adelRequest?: AdelRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling createAdelRequest.');
            }
            // verify required parameter 'xEmployeeId' is not null or undefined
            if (xEmployeeId === null || xEmployeeId === undefined) {
                throw new RequiredError('xEmployeeId','Required parameter xEmployeeId was null or undefined when calling createAdelRequest.');
            }
            const localVarPath = `/clients/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sp-token required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("sp-token", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (xTrackingId !== undefined && xTrackingId !== null) {
                localVarHeaderParameter['X-TrackingId'] = String(xTrackingId);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (xEmployeeId !== undefined && xEmployeeId !== null) {
                localVarHeaderParameter['X-EmployeeId'] = String(xEmployeeId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof adelRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(adelRequest !== undefined ? adelRequest : {})
                : (adelRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets list of clients requests
         * @param {string} authorization Authorization token
         * @param {string} xEmployeeId Identification of the employee
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdelRequests: async (authorization: string, xEmployeeId: string, xTrackingId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling listAdelRequests.');
            }
            // verify required parameter 'xEmployeeId' is not null or undefined
            if (xEmployeeId === null || xEmployeeId === undefined) {
                throw new RequiredError('xEmployeeId','Required parameter xEmployeeId was null or undefined when calling listAdelRequests.');
            }
            const localVarPath = `/clients/requests-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sp-token required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("sp-token", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (xTrackingId !== undefined && xTrackingId !== null) {
                localVarHeaderParameter['X-TrackingId'] = String(xTrackingId);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (xEmployeeId !== undefined && xEmployeeId !== null) {
                localVarHeaderParameter['X-EmployeeId'] = String(xEmployeeId);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets list of clients requests
         * @param {string} authorization Authorization token
         * @param {string} xEmployeeId Identification of the employee
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {ProcessAdelRequest} [processAdelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAdelRequest: async (authorization: string, xEmployeeId: string, xTrackingId?: string, processAdelRequest?: ProcessAdelRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling processAdelRequest.');
            }
            // verify required parameter 'xEmployeeId' is not null or undefined
            if (xEmployeeId === null || xEmployeeId === undefined) {
                throw new RequiredError('xEmployeeId','Required parameter xEmployeeId was null or undefined when calling processAdelRequest.');
            }
            const localVarPath = `/clients/process-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sp-token required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("sp-token", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (xTrackingId !== undefined && xTrackingId !== null) {
                localVarHeaderParameter['X-TrackingId'] = String(xTrackingId);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (xEmployeeId !== undefined && xEmployeeId !== null) {
                localVarHeaderParameter['X-EmployeeId'] = String(xEmployeeId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof processAdelRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(processAdelRequest !== undefined ? processAdelRequest : {})
                : (processAdelRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsApi - functional programming interface
 * @export
 */
export const ClientsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Creates new clients Adel request
         * @param {string} authorization Authorization token
         * @param {string} xEmployeeId Identification of the employee
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {AdelRequest} [adelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdelRequest(authorization: string, xEmployeeId: string, xTrackingId?: string, adelRequest?: AdelRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdelRequest>> {
            const localVarAxiosArgs = await ClientsApiAxiosParamCreator(configuration).createAdelRequest(authorization, xEmployeeId, xTrackingId, adelRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Gets list of clients requests
         * @param {string} authorization Authorization token
         * @param {string} xEmployeeId Identification of the employee
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAdelRequests(authorization: string, xEmployeeId: string, xTrackingId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdelRequest>>> {
            const localVarAxiosArgs = await ClientsApiAxiosParamCreator(configuration).listAdelRequests(authorization, xEmployeeId, xTrackingId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Gets list of clients requests
         * @param {string} authorization Authorization token
         * @param {string} xEmployeeId Identification of the employee
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {ProcessAdelRequest} [processAdelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processAdelRequest(authorization: string, xEmployeeId: string, xTrackingId?: string, processAdelRequest?: ProcessAdelRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessAdelResponse>> {
            const localVarAxiosArgs = await ClientsApiAxiosParamCreator(configuration).processAdelRequest(authorization, xEmployeeId, xTrackingId, processAdelRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ClientsApi - factory interface
 * @export
 */
export const ClientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Creates new clients Adel request
         * @param {string} authorization Authorization token
         * @param {string} xEmployeeId Identification of the employee
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {AdelRequest} [adelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdelRequest(authorization: string, xEmployeeId: string, xTrackingId?: string, adelRequest?: AdelRequest, options?: any): AxiosPromise<AdelRequest> {
            return ClientsApiFp(configuration).createAdelRequest(authorization, xEmployeeId, xTrackingId, adelRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets list of clients requests
         * @param {string} authorization Authorization token
         * @param {string} xEmployeeId Identification of the employee
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdelRequests(authorization: string, xEmployeeId: string, xTrackingId?: string, options?: any): AxiosPromise<Array<AdelRequest>> {
            return ClientsApiFp(configuration).listAdelRequests(authorization, xEmployeeId, xTrackingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets list of clients requests
         * @param {string} authorization Authorization token
         * @param {string} xEmployeeId Identification of the employee
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {ProcessAdelRequest} [processAdelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAdelRequest(authorization: string, xEmployeeId: string, xTrackingId?: string, processAdelRequest?: ProcessAdelRequest, options?: any): AxiosPromise<ProcessAdelResponse> {
            return ClientsApiFp(configuration).processAdelRequest(authorization, xEmployeeId, xTrackingId, processAdelRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientsApi - object-oriented interface
 * @export
 * @class ClientsApi
 * @extends {BaseAPI}
 */
export class ClientsApi extends BaseAPI {
    /**
     * Creates new clients Adel request
     * @param {string} authorization Authorization token
     * @param {string} xEmployeeId Identification of the employee
     * @param {string} [xTrackingId] Tracking ID for logging
     * @param {AdelRequest} [adelRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public createAdelRequest(authorization: string, xEmployeeId: string, xTrackingId?: string, adelRequest?: AdelRequest, options?: any) {
        return ClientsApiFp(this.configuration).createAdelRequest(authorization, xEmployeeId, xTrackingId, adelRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets list of clients requests
     * @param {string} authorization Authorization token
     * @param {string} xEmployeeId Identification of the employee
     * @param {string} [xTrackingId] Tracking ID for logging
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public listAdelRequests(authorization: string, xEmployeeId: string, xTrackingId?: string, options?: any) {
        return ClientsApiFp(this.configuration).listAdelRequests(authorization, xEmployeeId, xTrackingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets list of clients requests
     * @param {string} authorization Authorization token
     * @param {string} xEmployeeId Identification of the employee
     * @param {string} [xTrackingId] Tracking ID for logging
     * @param {ProcessAdelRequest} [processAdelRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public processAdelRequest(authorization: string, xEmployeeId: string, xTrackingId?: string, processAdelRequest?: ProcessAdelRequest, options?: any) {
        return ClientsApiFp(this.configuration).processAdelRequest(authorization, xEmployeeId, xTrackingId, processAdelRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Convert document into PDF a gets it as byte array.
         * @param {ConvertDocRequest} [caseCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertDocument: async (caseCreateRequest?: ConvertDocRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/convert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof caseCreateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(caseCreateRequest !== undefined ? caseCreateRequest : {})
                : (caseCreateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all existing branch offices
         * @param {string} authorization Authorization token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchOffices: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling getBranchOffices.');
            }
            const localVarPath = `/user/branch-offices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sp-token required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("sp-token", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets confimation about clients income and outcome as PDF in base64 format.
         * @param {string} authorization Authorization token
         * @param {string} xEmployeeId Identification of the employee
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {GetConfirmationDocReq} [getConfirmationDocReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfirmationDoc: async (authorization: string, xEmployeeId: string, xTrackingId?: string, getConfirmationDocReq?: GetConfirmationDocReq, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling getConfirmationDoc.');
            }
            // verify required parameter 'xEmployeeId' is not null or undefined
            if (xEmployeeId === null || xEmployeeId === undefined) {
                throw new RequiredError('xEmployeeId','Required parameter xEmployeeId was null or undefined when calling getConfirmationDoc.');
            }
            const localVarPath = `/clients/get-confirmation-doc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sp-token required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("sp-token", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (xTrackingId !== undefined && xTrackingId !== null) {
                localVarHeaderParameter['X-TrackingId'] = String(xTrackingId);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (xEmployeeId !== undefined && xEmployeeId !== null) {
                localVarHeaderParameter['X-EmployeeId'] = String(xEmployeeId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getConfirmationDocReq !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getConfirmationDocReq !== undefined ? getConfirmationDocReq : {})
                : (getConfirmationDocReq || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of rights for the given role.
         * @param {string} authorization Authorization token
         * @param {string} idRole The role id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleRights: async (authorization: string, idRole: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling getRoleRights.');
            }
            // verify required parameter 'idRole' is not null or undefined
            if (idRole === null || idRole === undefined) {
                throw new RequiredError('idRole','Required parameter idRole was null or undefined when calling getRoleRights.');
            }
            const localVarPath = `/user/roleright/{idRole}`
                .replace(`{${"idRole"}}`, encodeURIComponent(String(idRole)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sp-token required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("sp-token", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a detail of the given user.
         * @param {string} authorization Authorization token
         * @param {string} id Identification of the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (authorization: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling getUser.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUser.');
            }
            const localVarPath = `/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sp-token required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("sp-token", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Optional extended description in Markdown.
         * @summary Returns a list of users.
         * @param {string} authorization Authorization token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling getUsers.');
            }
            const localVarPath = `/users/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sp-token required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("sp-token", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authorize employee and returns token
         * @param {LoginReq} [processBankAccountReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (processBankAccountReq?: LoginReq, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof processBankAccountReq !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(processBankAccountReq !== undefined ? processBankAccountReq : {})
                : (processBankAccountReq || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of accounts for given case ID.
         * @summary Settings for application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sp-token required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("sp-token", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Convert document into PDF a gets it as byte array.
         * @param {ConvertDocRequest} [caseCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async convertDocument(caseCreateRequest?: ConvertDocRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConvertDocResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).convertDocument(caseCreateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns all existing branch offices
         * @param {string} authorization Authorization token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBranchOffices(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BranchOffice>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getBranchOffices(authorization, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets confimation about clients income and outcome as PDF in base64 format.
         * @param {string} authorization Authorization token
         * @param {string} xEmployeeId Identification of the employee
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {GetConfirmationDocReq} [getConfirmationDocReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfirmationDoc(authorization: string, xEmployeeId: string, xTrackingId?: string, getConfirmationDocReq?: GetConfirmationDocReq, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConfirmationDocRes>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getConfirmationDoc(authorization, xEmployeeId, xTrackingId, getConfirmationDocReq, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns a list of rights for the given role.
         * @param {string} authorization Authorization token
         * @param {string} idRole The role id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoleRights(authorization: string, idRole: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleRight>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getRoleRights(authorization, idRole, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns a detail of the given user.
         * @param {string} authorization Authorization token
         * @param {string} id Identification of the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(authorization: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getUser(authorization, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Optional extended description in Markdown.
         * @summary Returns a list of users.
         * @param {string} authorization Authorization token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getUsers(authorization, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Authorize employee and returns token
         * @param {LoginReq} [processBankAccountReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(processBankAccountReq?: LoginReq, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginRes>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).login(processBankAccountReq, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns a list of accounts for given case ID.
         * @summary Settings for application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).settings(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Convert document into PDF a gets it as byte array.
         * @param {ConvertDocRequest} [caseCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertDocument(caseCreateRequest?: ConvertDocRequest, options?: any): AxiosPromise<ConvertDocResponse> {
            return DefaultApiFp(configuration).convertDocument(caseCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all existing branch offices
         * @param {string} authorization Authorization token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchOffices(authorization: string, options?: any): AxiosPromise<Array<BranchOffice>> {
            return DefaultApiFp(configuration).getBranchOffices(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets confimation about clients income and outcome as PDF in base64 format.
         * @param {string} authorization Authorization token
         * @param {string} xEmployeeId Identification of the employee
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {GetConfirmationDocReq} [getConfirmationDocReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfirmationDoc(authorization: string, xEmployeeId: string, xTrackingId?: string, getConfirmationDocReq?: GetConfirmationDocReq, options?: any): AxiosPromise<GetConfirmationDocRes> {
            return DefaultApiFp(configuration).getConfirmationDoc(authorization, xEmployeeId, xTrackingId, getConfirmationDocReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of rights for the given role.
         * @param {string} authorization Authorization token
         * @param {string} idRole The role id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleRights(authorization: string, idRole: string, options?: any): AxiosPromise<Array<RoleRight>> {
            return DefaultApiFp(configuration).getRoleRights(authorization, idRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a detail of the given user.
         * @param {string} authorization Authorization token
         * @param {string} id Identification of the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(authorization: string, id: string, options?: any): AxiosPromise<User> {
            return DefaultApiFp(configuration).getUser(authorization, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Optional extended description in Markdown.
         * @summary Returns a list of users.
         * @param {string} authorization Authorization token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(authorization: string, options?: any): AxiosPromise<Array<User>> {
            return DefaultApiFp(configuration).getUsers(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authorize employee and returns token
         * @param {LoginReq} [processBankAccountReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(processBankAccountReq?: LoginReq, options?: any): AxiosPromise<LoginRes> {
            return DefaultApiFp(configuration).login(processBankAccountReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of accounts for given case ID.
         * @summary Settings for application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settings(options?: any): AxiosPromise<object> {
            return DefaultApiFp(configuration).settings(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Convert document into PDF a gets it as byte array.
     * @param {ConvertDocRequest} [caseCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public convertDocument(caseCreateRequest?: ConvertDocRequest, options?: any) {
        return DefaultApiFp(this.configuration).convertDocument(caseCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all existing branch offices
     * @param {string} authorization Authorization token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBranchOffices(authorization: string, options?: any) {
        return DefaultApiFp(this.configuration).getBranchOffices(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets confimation about clients income and outcome as PDF in base64 format.
     * @param {string} authorization Authorization token
     * @param {string} xEmployeeId Identification of the employee
     * @param {string} [xTrackingId] Tracking ID for logging
     * @param {GetConfirmationDocReq} [getConfirmationDocReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getConfirmationDoc(authorization: string, xEmployeeId: string, xTrackingId?: string, getConfirmationDocReq?: GetConfirmationDocReq, options?: any) {
        return DefaultApiFp(this.configuration).getConfirmationDoc(authorization, xEmployeeId, xTrackingId, getConfirmationDocReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of rights for the given role.
     * @param {string} authorization Authorization token
     * @param {string} idRole The role id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRoleRights(authorization: string, idRole: string, options?: any) {
        return DefaultApiFp(this.configuration).getRoleRights(authorization, idRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a detail of the given user.
     * @param {string} authorization Authorization token
     * @param {string} id Identification of the employee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUser(authorization: string, id: string, options?: any) {
        return DefaultApiFp(this.configuration).getUser(authorization, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Optional extended description in Markdown.
     * @summary Returns a list of users.
     * @param {string} authorization Authorization token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUsers(authorization: string, options?: any) {
        return DefaultApiFp(this.configuration).getUsers(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authorize employee and returns token
     * @param {LoginReq} [processBankAccountReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public login(processBankAccountReq?: LoginReq, options?: any) {
        return DefaultApiFp(this.configuration).login(processBankAccountReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of accounts for given case ID.
     * @summary Settings for application.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public settings(options?: any) {
        return DefaultApiFp(this.configuration).settings(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaskSchedulerApi - axios parameter creator
 * @export
 */
export const TaskSchedulerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update cases if client have confirmed their identity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAdelRequests: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/task-scheduler/check-adel-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskSchedulerApi - functional programming interface
 * @export
 */
export const TaskSchedulerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update cases if client have confirmed their identity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkAdelRequests(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TaskSchedulerApiAxiosParamCreator(configuration).checkAdelRequests(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TaskSchedulerApi - factory interface
 * @export
 */
export const TaskSchedulerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Update cases if client have confirmed their identity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAdelRequests(options?: any): AxiosPromise<void> {
            return TaskSchedulerApiFp(configuration).checkAdelRequests(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaskSchedulerApi - object-oriented interface
 * @export
 * @class TaskSchedulerApi
 * @extends {BaseAPI}
 */
export class TaskSchedulerApi extends BaseAPI {
    /**
     * 
     * @summary Update cases if client have confirmed their identity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskSchedulerApi
     */
    public checkAdelRequests(options?: any) {
        return TaskSchedulerApiFp(this.configuration).checkAdelRequests(options).then((request) => request(this.axios, this.basePath));
    }
}


