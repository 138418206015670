import { atom } from "recoil"
import { sessionStorageEffect } from "./index"

export interface IMSAL {
  REACT_AUTH_CLIENT_ID: string
  REACT_AUTH_AUTHORITY: string
  REACT_AUTH_REDIRECT_URI: string
  REACT_AUTH_LOG_OUT: string
  REACT_AUTH_SCOPE: string
  REACT_APP_BACKEND_URL: string
}

enum Keys {
  MSAL = "MSAL",
}

export const msalState = atom({
  key: Keys.MSAL,
  default: null,
  effects_UNSTABLE: [sessionStorageEffect<IMSAL | null>(Keys.MSAL)],
})
