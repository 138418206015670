import React, { useEffect, useState } from "react"
import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import useStyles from "./aad-login.styles"
import { useTranslation } from "react-i18next"
import { Container } from "@mui/material"
import { useRecoilState } from "recoil"
import { authTypeState } from "../../atoms/application"
import { useNavigate } from "react-router"
import { Alert } from "@mui/lab"
import { userState } from "../../atoms/usermanagement"
import { loggedState } from "../../atoms/infrastructure"
import { Path } from "../../pages"
import { InteractionStatus } from "@azure/msal-browser"
import { msalState } from "../../atoms/msal"

const AADLogin: React.FC = () => {
  const [serverError, setServerError] = useState<string | null>(null)
  const classes = useStyles({})
  const { t } = useTranslation()
  const [, setAuthType] = useRecoilState(authTypeState)
  const [, setLogged] = useRecoilState(loggedState)
  const [user, setUser] = useRecoilState(userState)
  const navigate = useNavigate()
  const { instance, accounts, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const [msalConfig] = useRecoilState(msalState)

  useEffect(() => {
    // Check if instance and t are created
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      const loginRequest = {
        scopes: [msalConfig!.REACT_AUTH_SCOPE],
      }
      instance
        .loginRedirect(loginRequest)
        .catch(() => setServerError(t("Něco se nepovedlo. Zkuste to znovu.")))
    }
  }, [isAuthenticated, inProgress, instance, t])

  useEffect(
    () => {
      setServerError(null)
      const loginRequest = {
        scopes: [msalConfig!.REACT_AUTH_SCOPE],
      }

      if (isAuthenticated) {
        const account = accounts[0]
        const name = account?.name?.split(" ")

        instance
          .acquireTokenSilent({
            ...loginRequest,
            account: account,
            forceRefresh: true,
          })
          .then((response) => {
            setAuthType("aad")
            setLogged(true)
            setUser({
              name: name?.[0] as string,
              surname: name?.[1] as string,
              email: account.username,
            })
          })
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [isAuthenticated, accounts]
  )

  useEffect(
    () => {
      if (isAuthenticated) {
        // redirect to main page
        navigate(Path.MAIN_PAGE)
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [isAuthenticated]
  )

  return (
    <Container>
      <div className={classes.root}>
        {serverError && <Alert severity="error">{serverError}</Alert>}
      </div>
    </Container>
  )
}

export default AADLogin
