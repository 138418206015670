import { Theme } from "@mui/material"
import { createStyles, makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
      },
      "& > div:first-child": {
        borderBottom: "1px solid",
        borderColor: theme.palette.primary.main,
        paddingBottom: "20px",
        [theme.breakpoints.up("md")]: {
          borderRight: "1px solid",
          borderBottom: 0,
          paddingBottom: 0,
          paddingRight: "40px",
        }
      },
      "& > div:nth-child(2)": {
        paddingTop: "20px",
        [theme.breakpoints.up("md")]: {
          paddingTop: 0,
          paddingLeft: "40px",
        }
      },
      "& > *": {
        // margin: "0px 16px",
      },
    },
    container: {
      width: "300px",
      display: "flex",
      flexDirection: "column",
    },
    maxContent: {
      flexGrow: 1,
    },
    footer: {
      display: "flex",
      justifyContent: "space-around",
    },
    iconBack: {
      position: "absolute",
      left: 0,
      top: "calc(50% - 24px)",
    },
  })
})

export default useStyles
