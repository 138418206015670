import { Theme } from "@mui/material"
import { createStyles, makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      "& > *": {
        marginBottom: "22px",
      },
    },
    footer: {
      display: "flex",
      justifyContent: "space-around",
    },
    submitButton: {
      width: "100%",
    },
  })
})

export default useStyles
