/**
 * Create function react component which return tooltip
 * tooltip render status pass as props to application
 */
import React from "react"
import { Tooltip } from "@mui/material"

export const TooltipStatus: React.FC<{ status: string | undefined }> = ({
  status,
  children,
}) => {
  // Check if status is empty or finished tooltip is not rendered are rendered only children
  if (status == null || status === "AUTHORIZED" || status === "FINISHED") {
    return <>{children}</>
  }
  // Render MUI tooltip with status
  return (
    <Tooltip title={status} arrow={true} placement={"left"}>
      {children}
    </Tooltip>
  )
}
