import { Theme } from "@mui/material"
import { createStyles, makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.grey[200],
      padding: "2px 0 10px",
      [theme.breakpoints.up("sm")]: {
        padding: "20px 0 60px",
      },
    },
    copy: {
      lineHeight: "50px",
      textAlign: "right",
      padding: "0px",
    },
  })
)

export default useStyles
