import { useRecoilState } from "recoil"
import { listApplicationsState } from "../atoms/application"

export const useData = () => {
  const [listApplications, setListApplications] = useRecoilState(
    listApplicationsState
  )
  const setRow = (row) => {
    const newData = listApplications.map((item) => {
      if (item.caseId === row.caseId) {
        return {
          ...item,
          ...row,
        }
      }
      return item
    })
    setListApplications(newData)
  }
  return { setRow }
}
