import React from "react"
import ReactDOM from "react-dom"
import createCache from '@emotion/cache';
import { CacheProvider } from "@emotion/react";
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"
import { ThemeProvider } from "@mui/material/styles"
import theme from "./theme"
import { RecoilRoot } from "recoil"
import { QueryParamProvider } from "use-query-params"
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import "./assets/fonts/VWAG_TheSans_TrueType/VWAGTheSans-Regular.ttf"

const muiCache = createCache({
  key: "mui",
  prepend: true,
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CacheProvider value={muiCache}>
        <RecoilRoot>
          <BrowserRouter>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <App />
            </QueryParamProvider>
          </BrowserRouter>
        </RecoilRoot>
      </CacheProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
