import React from "react"
import useStyles from "./body.styles"
import AADLogin from "../aad-login"
import AADLogout from "../aad-logout"
import { Route, Routes } from "react-router"
import Private from "../../pages/private"
import { Path } from "../../pages"

const Body: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Routes>
        <Route path={Path.AAD_LOGIN} element={<AADLogin />} />
        <Route path={Path.AAD_LOGOUT} element={<AADLogout />} />
        <Route path="*" element={<Private />} />
      </Routes>
    </div>
  )
}

export default Body
