import { Theme } from "@mui/material"
import { createStyles, makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: "40px",
      paddingBottom: "40px",
      paddingLeft: "0px",
      paddingRight: "0px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      margin: "auto auto",
      maxWidth: "560px",
    },
    submitContainer: {
      textAlign: "center",
    },
    submitButton: {
      textTransform: "uppercase",
      display: "inline-block",
      width: "100%",
    },
  })
)

export default useStyles
