import { Theme } from "@mui/material"
import { createStyles, makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
    checkmarkIcon: {
      width: "25px",
      height: "25px",
      padding: "15px",
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: "40px",
      fill: theme.palette.primary.main,
    },
    linkSendAgain: {
      marginLeft: 6,
      "&:hover": {
        textDecoration: "none",
      },
    },
  })
})

export default useStyles
