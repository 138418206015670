import React, {FC, useCallback, useEffect} from "react"
import {
  Box,
  Button,
  DialogActions,
  FormControl,
  TextField,
} from "@mui/material"
import {useTranslation} from "react-i18next"
import useStylesFirstPage from "./firstPage.styles"
import {useForm} from "react-hook-form"
import {useRecoilState} from "recoil"
import {formNewApplicationState} from "../../../atoms/application"
import MaskedInput from "react-text-mask"
import {info} from "../../../logger"

interface IFirstPage {
  handleModalDialogPage: (page: number) => void
}

export const FirstPage: FC<IFirstPage> = ({handleModalDialogPage}) => {
  const [t] = useTranslation()
  const classesFirstPage = useStylesFirstPage()
  const [formNewApplication, setFormNewApplication] = useRecoilState(
    formNewApplicationState
  )
  const {
    register,
    formState: {isValid, errors},
    trigger,
    setValue,
    getValues,
  } = useForm({mode: "onChange"})

  const handleNextContainerClick = useCallback(async () => {
    await trigger()
  }, [trigger])

  const handleNextClick = useCallback(() => {
    handleModalDialogPage(2)
  }, [handleModalDialogPage])

  const handleChange = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const {name, value} = e.target
      setValue(name, value)
      setFormNewApplication({...formNewApplication, [name]: value})
      await trigger(name)
    },
    [trigger, setValue, formNewApplication, setFormNewApplication]
  )

  useEffect(() => {
    register("name", {
      required: "Jméno je povinné",
      pattern: {
        value: /^[a-zá-žA-ZÁ-Ž]+$/i,
        message: "Jméno obsahuje neplatné znaky",
      },
    })
    register("surname", {
      required: "Příjmení je povinné",
      pattern: {
        value: /^[a-zá-žA-ZÁ-Ž]+$/,
        message: "Příjmení obsahuje neplatné znaky",
      },
    })
    register("company", {
      required: "Firma je povinná",
    })
    register("phone", {
      required: "Telefonní číslo je povinné",
      pattern: {
        value: /^(\(\+420\))\s{1}[0-9]{3}[-][0-9]{3}[-][0-9]{3}$/i,
        message: "Zadejte telefonní číslo ve formátu (+420) 123-456-789",
      },
    })
    register("email", {
      required: "Email je povinný",
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Zadejte email ve správnem formátu",
      },
    })
  }, [register])

  info([`Errors:`, errors])

  useEffect(() => {
    setValue("name", formNewApplication.name)
    setValue("surname", formNewApplication.surname)
    setValue("company", formNewApplication.company)
    setValue("phone", formNewApplication.phone)
    setValue("email", formNewApplication.email)
    if (
      formNewApplication.name &&
      formNewApplication.surname &&
      formNewApplication.company &&
      formNewApplication.phone &&
      formNewApplication.email
    ) {
      trigger()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <form className={classesFirstPage.root} autoComplete="off">
        <TextField
          value={getValues("name")}
          error={!!errors.name}
          autoFocus={true}
          label={t("Jméno")}
          required={true}
          onChange={handleChange}
          name="name"
          helperText={!!errors.name && <>{errors.name?.message}</>}
          variant="outlined"
        />
        <TextField
          value={getValues("surname")}
          error={!!errors.surname}
          label={t("Příjmení")}
          required={true}
          onChange={handleChange}
          name="surname"
          helperText={!!errors.surname && <>{errors.surname?.message}</>}
          variant="outlined"
        />
        <TextField
          value={getValues("company")}
          error={!!errors.company}
          label={t("Firma")}
          required={true}
          onChange={handleChange}
          name="company"
          helperText={!!errors.company && <>{errors.company?.message}</>}
          variant="outlined"
        />
        <FormControl>
          <MaskedInput
            guide={false}
            keepCharPositions={true}
            mask={[
              "(",
              "+",
              "4",
              "2",
              "0",
              ")",
              " ",
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
            ]}
            value={getValues("phone")}
            showMask={true}
            onChange={(e) => { handleChange(e) }}
            render={(ref, props) => (
              <TextField
                inputRef={ref}
                name="phone"
                label={t("Telefonní číslo")}
                required={true}
                error={!!errors.phone}
                helperText={!!errors.phone && <>{errors.phone?.message}</>}
                id="phone"
                variant="outlined"
                onChange={(e) => { props.onChange(e); }}
              />
            )}
          />
        </FormControl>
        <TextField
          value={getValues("email")}
          label={t("E-mail")}
          name="email"
          id="email"
          onChange={handleChange}
          required={true}
          error={!!errors.email}
          helperText={<>{errors.email?.message}</>}
          variant="outlined"
        />
        <DialogActions className={classesFirstPage.footer}>
          <Box onClick={handleNextContainerClick} sx={{width: "100%"}}>
            <Button
              color="primary"
              variant="contained"
              disableElevation={true}
              disabled={!isValid}
              onClick={handleNextClick}
              type={"submit"}
              className={classesFirstPage.submitButton}
            >
              {t("pokračovat")}
            </Button>
          </Box>
        </DialogActions>
      </form>
    </>
  )
}
