import { useAxios } from "../../hooks/useAxios"
import { Box } from "@mui/material"
import ErrorDialog from "../error/error-dialog"
import React, { useCallback } from "react"
import { useAADProlong } from "../../hooks/useAADProlong"

const Interceptor = ({ children }) => {
  // Prolongation of access bearer token
  useAADProlong()
  // Interceptor for bearer for axios
  const { openErrorDialog, setOpenErrorDialog } = useAxios()
  const handleErrorDialogClose = useCallback(() => {
    setOpenErrorDialog(false)
  }, [setOpenErrorDialog])
  return (
    <Box>
      <>{children}</>
      <ErrorDialog
        open={openErrorDialog}
        handleDialogClose={handleErrorDialogClose}
      />
    </Box>
  )
}

export default Interceptor
