import React, { useEffect } from "react"
import { MsalProvider } from "@azure/msal-react"
import {
  BrowserCacheLocation,
  PublicClientApplication,
} from "@azure/msal-browser"
import "./App.css"
import Header from "./components/header"
import Body from "./components/body"
import Footer from "./components/footer"
import MoveTop from "./components/moveTop/moveTop"
import Loading from "./components/loading/loading"
import ErrorBoundaries from "./components/errorBoundaries/ErrorBoundaries"
import ErrorAdelDialog from "./components/error/error-adel-dialog"
import { useRecoilState } from "recoil"
import { msalState } from "./atoms/msal"
import Interceptor from "./components/interceptor/interceptor"
import { Path } from "./pages"

function App() {
  const [, setMsal] = useRecoilState(msalState)
  const [msalConfig, setMsalConfig] = React.useState<any>(null)
  // Read data from backend and create msalConfig
  useEffect(() => {
    const response = fetch("/env")
    response.then(async (response) => {
      const json = response.json()
      setMsal({
        REACT_AUTH_CLIENT_ID: (await json).REACT_AUTH_CLIENT_ID.toString(),
        REACT_AUTH_AUTHORITY: (await json).REACT_AUTH_AUTHORITY.toString(),
        REACT_AUTH_REDIRECT_URI: (
          await json
        ).REACT_AUTH_REDIRECT_URI.toString(),
        REACT_AUTH_LOG_OUT: (await json).REACT_AUTH_LOG_OUT.toString(),
        REACT_AUTH_SCOPE: (await json).REACT_AUTH_SCOPE.toString(),
        REACT_APP_BACKEND_URL: (await json).REACT_APP_BACKEND_URL.toString(),
      })
      setMsalConfig({
        auth: {
          clientId: (await json).REACT_AUTH_CLIENT_ID,
          authority: (await json).REACT_AUTH_AUTHORITY,
          redirectUri: (await json).REACT_AUTH_REDIRECT_URI,
          postLogoutRedirectUri: (await json).REACT_AUTH_LOG_OUT,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.SessionStorage,
        },
      })
    })
  }, [setMsal])

  // if msalConfig is null, do not render the app
  if (!msalConfig) {
    return null
  }
  const msalInstance = new PublicClientApplication(msalConfig)
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])

  return (
    <ErrorBoundaries>
      <MsalProvider instance={msalInstance}>
        <Interceptor>
          <Header />
          <Body />
          <Footer />
          <MoveTop />
          <ErrorAdelDialog />
        </Interceptor>
      </MsalProvider>
      <Loading />
    </ErrorBoundaries>
  )
}

export default App
