import { Theme } from "@mui/material"
import { createStyles, makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: "inline-flex",
      flexDirection: "column",
      alignItems: "flex-end",
      gap: "8px",
      color: "white",
      [theme.breakpoints.up("lg")]: {
        flexDirection: "row",
        gap: "18px",
      },
      "& > *": {
        display: "inline-flex",
        whiteSpace: "nowrap",
      },
      "& .MuiTypography-body1": {
        color: "white",
      },
    },
    icon: {
      marginLeft: "8px",
      width: "20px",
      fill: "white",
    },
    iconUser: {
      "& > path": {
        stroke: "white",
        strokeWidth: "1",
      },
    },
    logoutLink: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    }
  })
})

export default useStyles
