import React from "react"
import MainPage from "src/components/mainPage"
import { useNavigate } from "react-router"
import { Path } from "../index"
import { useIsAuthenticated, useMsal } from "@azure/msal-react"

const Private: React.FC = () => {
  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate()

  // when bearer isn't set redirect to login page
  if (!isAuthenticated) {
    navigate(Path.AAD_LOGIN)
    return null
  }

  return (
    <>
      <MainPage />
      {/*<Expired />*/}
    </>
  )
}

export default Private
