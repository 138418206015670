// create REST api
import {
  ApplicationResultResponse,
  Configuration,
} from "../generated-sources/sp"
import { useRecoilState } from "recoil"
import { trackingState } from "../atoms/infrastructure"
import { useState } from "react"
import { error } from "../logger"
import { CasesApi } from "src/generated-sources/sp/api"
import { BACKEND_URL } from "../utils/const"

interface IGetApplicationResult {
  callApplicationResult: (caseId?: string) => Promise<boolean>
  applicationResult: ApplicationResultResponse | null
}

const api = new CasesApi(
  new Configuration({
    basePath: BACKEND_URL,
  })
)

export const useGetApplicationResult = (): IGetApplicationResult => {
  const [trackingId] = useRecoilState(trackingState)

  const [applicationResult, setApplicationResult] =
    useState<ApplicationResultResponse | null>(null)

  const callApplicationResult = async (caseId?: string): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      const response = await api.applicationResult(
        "",
        "",
        caseId || "",
        trackingId
      )
      if (response?.data) {
        setApplicationResult(response.data)
        resolve(true)
      } else {
        error("Data from backend are not returned")
        reject(false)
      }
    })
  }

  return {
    callApplicationResult,
    applicationResult,
  }
}
