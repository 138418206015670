import React from "react"
import useStyles from "./mainPage.styles"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"
import {Box, Button, Container, Link} from "@mui/material"
import List from "../application/list"
import ModalDialog from "../application/new/modalDialog"
import { useRecoilState } from "recoil"
import { modalDialogOpenState } from "../../atoms/application"
import { ReactComponent as DocumentIcon } from "../../assets/icons/content/doc-lines-key-chain.svg"

const MainPage: React.FC = () => {
  const classes = useStyles({})
  const [t] = useTranslation()
  // atom for open state
  const [modalDialogOpen, setModalDialogOpen] = useRecoilState(
    modalDialogOpenState
  )

  const handleNewApplicationButtonClick = React.useCallback(() => {
    setModalDialogOpen(true)
  }, [setModalDialogOpen])
  const handleModalDialogClose = React.useCallback(() => {
    setModalDialogOpen(false)
  }, [setModalDialogOpen])

  return (
    <div className={classes.root}>
      <ModalDialog
        open={modalDialogOpen}
        handleClose={handleModalDialogClose}
      />
      <Container className={classes.container}>
        <Typography component="h1" color="primary" variant="h3" noWrap>
          {t("Nová žádost")}
        </Typography>
        <Box className={classes.applicationBoxIcon} borderRadius="50%" border={1} borderColor="primary.main">
          <Link href="#" aria-label="add" onClick={handleNewApplicationButtonClick}>
            <DocumentIcon className={classes.applicationIcon} />
          </Link>
        </Box>
        <Button
          className={classes.newApplicationButton}
          color="primary"
          variant="contained"
          disableElevation={true}
          onClick={handleNewApplicationButtonClick}
        >
          {t("vytvořit")}
        </Button>
        <List />
      </Container>
    </div>
  )
}

export default MainPage
