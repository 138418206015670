import { red } from "@mui/material/colors"
import { createTheme } from "@mui/material/styles"

const h1 = {
  fontSize: "30px",
  fontWeight: 600,
  color: "#fff", backgroundColor: "#007CB0",
  "@media(min-width: 900px)": {
    fontSize: "36px",
  }
}

const h2 = {
  fontSize: "28px",
  fontWeight: 600,
  color: "#004666",
  "@media(min-width: 900px)": {
    fontSize: "32px",
  }
}

const subtitle1 = {
  fontSize: "18px",
  fontWeight: 400,
}

const subtitle2 = {
  fontSize: "15px",
  fontWeight: 400,
}

// A custom theme for this app
// @ts-ignore
const theme = createTheme({
  palette: {
    action: {
      hover: "rgb(102,228,238)",
      hoverOpacity: 0.25,
    },
    primary: {
      main: "#007CB0",
      light: "#FFFFFF",
      dark: "#005d83",
    },
    secondary: {
      main: "#66E4EE",
      light: "#D8D8D8", // SILVER
    },
    error: {
      main: red.A400,
      contrastText: "#FF4963",
    },
    background: {
      default: "#fff",
    },
    text: {
      secondary: "#000",
    },
    grey: {
      200: "#F6F6F6",
      300: '#D0D0CE',
      400: '#BBBCBC',
      500: '#A7A8AA',
      600: '#97999B',
      700: '#75787B',
      800: '#63666A',
      900: '#53565A',
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    },
  },
  typography: {
    fontFamily: ["'Open Sans', Verdana, Helvetica, sans-serif"].join(","),
    h1: {
      ...h1,
    },
    h2: {
      ...h2,
    },
    h3: {
      fontSize: "28px",
      fontWeight: 600,
      "@media(min-width: 900px)": {
        fontSize: "32px",
      }
    },
    body1: {
      fontSize: "18px",
      color: "#4C5356",
      letterSpacing: "0.2px",
    },
    body2: {
      fontSize: "15px",
      color: "white",
      letterSpacing: "0.2px",
    },
    button: {
      fontWeight: 700,
      fontSize: "16px",
      paddingLeft: "65px",
      paddingRight: "65px",
      paddingTop: "10px",
      paddingBottom: "10px",
      textTransform: "uppercase",
    },
    subtitle1,
    subtitle2,
  },
  components: {
    MuiTypography: {
    },
    // @ts-ignore this isn't in material typescript interface
    MuiAlert: {
      styleOverrides: {
        root: {
          textAlign: "center",
          border: "1px solid #FF4963",
        },
        standardError: {
          borderRadius: "8px",
          fontSize: "12px",
          color: "#FF4963",
          display: "inline-block",
        },
        icon: {
          display: "none",
        },
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          marginTop: "8px",
          marginLeft: 0,
          marginRight: 0,
        },
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: '#000',
          },
        },
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        formControl: {
          ...subtitle2,
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        notchedOutline: {
          borderWidth: "2px",
          borderColor: "#000",
          "*.Mui-focused &": {
            borderColor: "#000 !important",
          },
        },
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          minWidth: "188px",
          paddingLeft: "20px",
          paddingRight: "20px",
          height: "60px",
        },
        outlinedPrimary: {
          borderWidth: "2px",
          borderColor: "#007CB0",
          "&:hover": {
            borderWidth: "2px",
          },
        },
        outlinedSecondary: {
          color: "#004666",
          "&:hover": {
            backgroundColor: "white",
            boxShadow: "0 3px 4px 0 rgba(0,0,0,0.2) !important",
          },
        },
        containedSecondary: {
          "&:hover": {
            backgroundColor: "rgba(102,228,238, 0.65)",
            boxShadow: "0 3px 4px 0 rgba(0,0,0,0.2) !important",
          },
        },
        outlined: {
          padding: "10px 65px",
        },
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          margin: "24px",
          padding: "26px 48px",
          borderRadius: "4px",
        },
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "16px 0",
        },
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          flexDirection: "column",
          alignItems: "initial",
          justifyContent: "initial",
          marginTop: "28px",
          padding: "8px 0",
          gap: "8px",
        },
        spacing: {
          "& > :not(:first-child)": {
            margin: "0 !important",
          },
        },
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          margin: "0 auto",
          padding: "8px 0",
          width: "100%",
        },
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            boxShadow: "0 3px 4px 0 rgba(0,0,0,0.2) !important",
          },
        },
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // padding: "26px",
        },
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          padding: "4px",
        },
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "15px !important",
        },
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        menuItem: {
          "&.Mui-selected": {
            "&:hover": {
              color: "#4C5356",
            }
          },
          "&:hover": {
            backgroundColor: "#007CB0",
            color: "#FFF"
          }
        }
      }
    }
  },
})

export default theme
