import { TFunction } from "react-i18next"
import { ReloadApplication } from "./reloadApplication"
import { IRow } from "./IRow"
import DownloadPdf from "./downloadPdf"
import { getStatusTanslation } from "./utils"
import { Column } from "material-table"
import { ErrorShortPeriod } from "./errorShortPeriod"
import ContentLoader, { Code } from "react-content-loader"

const columns = (
  t: TFunction,
  setUserChangedStatus: (
    change: Array<{ id?: number; status: string }>
  ) => void,
  tableState: any
) => {
  const orderBy = tableState?.orderBy || 0
  const orderDirection = tableState?.orderDirection || "desc"
  const cols: Column<any>[] = [
    {
      title: t("ID Žádosti"),
      field: "idApplication",
      render: (rowData: IRow) => {
        const cell = rowData.idApplication
        // If is status CHECK_IN_PROCESS we will display placeholder
        if ("CHECK_IN_PROCESS" === rowData.statusCode) {
          return <Code />
        }
        return cell
      },
    },
    {
      title: t("Klient"),
      field: "client",
      render: (rowData: IRow) => {
        const cell = rowData.idApplication
        // If is status CHECK_IN_PROCESS we will display placeholder
        if ("CHECK_IN_PROCESS" === rowData.statusCode) {
          return <Code />
        }
        return rowData.client
      },
    },
    {
      title: t("Firma"),
      field: "company",
      render: (rowData: IRow) => {
        const cell = rowData.idApplication
        // If is status CHECK_IN_PROCESS we will display placeholder
        if ("CHECK_IN_PROCESS" === rowData.statusCode) {
          return <Code />
        }
        return rowData.company
      },
    },
    {
      title: t("Kontakt"),
      field: "phone",
      render: (rowData: IRow) => {
        const cell = rowData.idApplication
        // If is status CHECK_IN_PROCESS we will display placeholder
        if ("CHECK_IN_PROCESS" === rowData.statusCode) {
          return <Code />
        }
        if (rowData.phone) {
          const phone = rowData.phone
          if (phone.length === 13) {
            let countryCode = phone.slice(0, 4) // "+420"
            let firstPart = phone.slice(4, 7) // "773"
            let secondPart = phone.slice(7, 10) // "466"
            let thirdPart = phone.slice(10) // "427"
            return `${countryCode} ${firstPart}-${secondPart}-${thirdPart}`
          } else {
            return phone
          }
        }
        return ""
      },
    },
    {
      title: t("Datum"),
      field: "date",
      render: (rowData: IRow) => {
        const cell = rowData.idApplication
        // If is status CHECK_IN_PROCESS we will display placeholder
        if ("CHECK_IN_PROCESS" === rowData.statusCode) {
          return <Code />
        }
        return rowData.date
      },
    },
    {
      title: t("Stav žádosti"),
      field: "statusCode",
      render: (rowData: IRow) => {
        const cell = rowData.idApplication
        // If is status CHECK_IN_PROCESS we will display placeholder
        if ("CHECK_IN_PROCESS" === rowData.statusCode) {
          return <Code />
        }
        let color = "#3BA313"
        if (
          "ERROR" === rowData.statusCode ||
          "ERROR_SHORT_PERIOD" === rowData.statusCode
        ) {
          color = "red"
        }
        const text = getStatusTanslation(rowData.statusCode)
        return <span style={{ color }}>{text}</span>
      },
      cellStyle: {
        color: "#3BA313",
      },
    },
    {
      title: t("Vytvořil"),
      render: (rowData: IRow) => {
        const cell = rowData.idApplication
        // If is status CHECK_IN_PROCESS we will display placeholder
        if ("CHECK_IN_PROCESS" === rowData.statusCode) {
          return <Code />
        }
        return rowData.employee?.email
      },
    },
    {
      title: "",
      sorting: false,
      render: (rowData: IRow) => {
        return (
          <ReloadApplication
            rowData={rowData}
            setUserChangedStatus={setUserChangedStatus}
          />
        )
      },
      cellStyle: {
        padding: "0px",
        margin: "0px",
      },
    },
    {
      title: t("Příloha"),
      sorting: false,
      render: (rowData: IRow) => {
        if ("DONE" === rowData.statusCode) {
          return <DownloadPdf rowData={rowData} />
        }
        if ("ERROR_SHORT_PERIOD" === rowData.statusCode) {
          return <ErrorShortPeriod rowData={rowData} />
        }
        return ""
      },
      cellStyle: {
        padding: "0px",
        margin: "0px",
      },
    },
  ]
  cols[orderBy].defaultSort = orderDirection
  return cols
}

export default columns
