import React from "react"
import {
  AdelRequest,
  Client,
  ClientContactInfo,
  ClientsApiFactory,
  ProcessAdelRequest,
  ProcessAdelRequestConfirmationTypeEnum,
} from "../generated-sources/sp/api"
import { Configuration } from "../generated-sources/sp/configuration"
import { useRecoilState } from "recoil"
import { trackingState } from "../atoms/infrastructure"
import { loadingVisibleState } from "../atoms/loading"
import { IJWT, parseJWT } from "../utils/jwt"
import { error } from "../logger"
import { AxiosResponse } from "axios"
import { userState } from "../atoms/usermanagement"
import { BACKEND_URL } from "../utils/const"

export interface IPhoneCreateCase {
  createCase: (
    client: Client,
    idApplication: number | null
  ) => Promise<number | null>
  sendSms: (requestId: number, client: Client) => Promise<void>
}

// create REST api
const api = ClientsApiFactory(
  new Configuration({
    basePath: BACKEND_URL,
  })
)

export const usePhoneCreateCase = (): IPhoneCreateCase => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [trackingId, setTrackingId] = useRecoilState(trackingState)
  const [user] = useRecoilState(userState)
  const [, setLoadingVisible] = useRecoilState(loadingVisibleState)

  let newTrackingId: String | null = null

  const createCase = async (
    client: Client,
    inIdApplication: number | null
  ): Promise<number | null> => {
    const requestBody: AdelRequest = {
      client: {
        ...client,
        phone: client.phone?.replace(/^\(|\)|-| /g, ""),
      },
      employee: {
        email: user?.email,
      },
    }
    if (inIdApplication) {
      requestBody.id = inIdApplication
    }
    setLoadingVisible(true)
    const response: AxiosResponse<AdelRequest> = await api
      .createAdelRequest("", "", trackingId, requestBody)
      .finally(() => {
        setLoadingVisible(false)
      })
    // new tracking id is generated by bankid service
    newTrackingId = response.headers["x-trackingid"]
    setTrackingId(newTrackingId.toString())
    if (response.data.id) {
      return Promise.resolve(response.data.id)
    }

    return Promise.resolve(null)
  }

  const sendSms = async (requestId: number, client: Client) => {
    if (requestId) {
      const requestProcessBody: ProcessAdelRequest = {
        confirmationType: ProcessAdelRequestConfirmationTypeEnum.Mobile,
        requestId,
        clientContactInfo: {
          email: client?.email,
          phone: client.phone?.replace(/^\(|\)|-| /g, ""),
        },
      }

      setLoadingVisible(true)
      await api
        .processAdelRequest(
          "",
          "",
          newTrackingId?.toString(),
          requestProcessBody
        )
        .finally(() => {
          setLoadingVisible(false)
        })
    } else {
      error("RequestId is mandatory")
    }
  }

  return {
    createCase,
    sendSms,
  }
}
