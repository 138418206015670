import React, { useEffect } from "react"
import { useIsAuthenticated } from "@azure/msal-react"
import { useRecoilState } from "recoil"
import { authTypeState } from "../../atoms/application"
import { useNavigate } from "react-router"
import { userState } from "../../atoms/usermanagement"
import { loggedState } from "../../atoms/infrastructure"
import { Path } from "../../pages"

const AADLogout: React.FC = () => {
  const [, setAuthType] = useRecoilState(authTypeState)
  const [, setLogged] = useRecoilState(loggedState)
  const [, setUser] = useRecoilState(userState)
  const navigate = useNavigate()
  const isAuthenticated = useIsAuthenticated()

  useEffect(
    () => {
      if (!isAuthenticated) {
        setAuthType(null)
        setLogged(false)
        setUser(null)

        navigate(Path.AAD_LOGIN)
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [isAuthenticated]
  )
  return null
}

export default AADLogout
