import { Theme } from "@mui/material"
import { createStyles, makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      width: "132px",
      height: "25px",
    },
  })
})

export default useStyles
