import React from "react"
import useStyles from "./header.styles"
import { Container, Typography } from "@mui/material"
import Logo from "../logo/logo"
import UserMenu from "../menu/user/user"
import { useIsAuthenticated } from "@azure/msal-react"
import { useNavigate } from "react-router"

const Header: React.FC = () => {
  const classes = useStyles()
  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate()

  // when bearer isn't set redirect to login page
  if (!isAuthenticated) {
    return null
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div className={classes.top}>
          <div className={classes.logo}>
            <Logo></Logo>
          </div>
          <UserMenu />
        </div>

        <div className={classes.main}>
          <Typography component="h1" variant="h1">
            {"Portál ověřování identity"}
          </Typography>
        </div>
      </Container>
    </div>
  )
}

export default Header
