/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useCallback, useEffect} from "react"
import {useTranslation} from "react-i18next"
import {useStylesThirdPage} from "./index"
import {Button, IconButton} from "@mui/material"
import {useRecoilState} from "recoil"
import {formNewApplicationState, idState} from "../../../atoms/application"
import ArrowBack from "@mui/icons-material/ArrowBack"
import Typography from "@mui/material/Typography"
import {useListApplication} from "../../../hooks/useListApplication";
import {usePhoneCreateCase} from "../../../hooks/usePhoneCreateCase"
import {useEmailCreateCase} from "../../../hooks/useEmailCreateCase";
import {createClient} from "./utils"

interface IThirdPage {
  handleModalDialogPage: (page: number) => void
  handleDialogClose: () => void
}

export const ThirdPage: FC<IThirdPage> = ({
                                            handleModalDialogPage,
                                            handleDialogClose,
                                          }) => {
  const [idApplication, setIdApplication] = useRecoilState(idState)
  const [t] = useTranslation()
  const classesThirdPage = useStylesThirdPage({})
  const {search: caseList} = useListApplication()
  const {createCase: phoneCreateCase, sendSms} = usePhoneCreateCase()
  const {createCase: emailCreateCase, sendEmail} = useEmailCreateCase()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formNewApplication] = useRecoilState(formNewApplicationState)

  const handlePreviousClick = useCallback(() => {
    handleModalDialogPage(0)
  }, [handleModalDialogPage])

  const onFillEmailClick = useCallback(async () => {
    const requestId: number | null = await emailCreateCase(
      createClient(formNewApplication),
      idApplication
    )
    if (requestId) {
      setIdApplication(requestId)
      caseList()
      await sendEmail(requestId, formNewApplication)
      handleModalDialogPage(4)
    }
  }, [
    formNewApplication,
    handleModalDialogPage,
    idApplication,
    emailCreateCase,
    sendEmail,
    setIdApplication
  ])

  const onFillPhoneClick = useCallback(async () => {
    const requestId: number | null = await phoneCreateCase(
      createClient(formNewApplication),
      idApplication
    )
    if (requestId) {
      setIdApplication(requestId)
      caseList()
      await sendSms(requestId, formNewApplication)
      handleModalDialogPage(3)
    }
  }, [
    formNewApplication,
    handleModalDialogPage,
    idApplication,
    phoneCreateCase,
    sendSms,
    setIdApplication,
  ])

  useEffect(() => {
    setIdApplication(null)
  }, [])

  return (
    <>
      <IconButton
        className={classesThirdPage.iconBack}
        color="primary"
        onClick={handlePreviousClick}
      >
        <ArrowBack/>
      </IconButton>

      <div className={classesThirdPage.root}>
        <div className={classesThirdPage.container}>
          <Typography component="h2" variant="h2" color="primary">
            {t("Ověřit mobilním telefonem")}
          </Typography>
          <p>{t("Žadatel bude pokračovat na svém mobilním telefonu.")}</p>
          <p className={classesThirdPage.maxContent}>
            {t(
              "O úspěšném ověření, budete informováni."
            )}
          </p>
          <p>
            <Button
              id="fillPhone"
              color="primary"
              variant="contained"
              disableElevation={true}
              onClick={onFillPhoneClick}
            >
              {t("odeslat sms")}
            </Button>
          </p>
        </div>
        <div className={classesThirdPage.container}>
          <Typography component="h2" variant="h2" color="primary">
            {t("Ověřit na počítači")}
          </Typography>
          <p className={classesThirdPage.maxContent}>
            {t(
              "Žadatel bude pokračovat na svém počítači."
            )}
          </p>
          <p className={classesThirdPage.maxContent}>
            {t(
              "O úspěšném ověření, budete informováni."
            )}
          </p>
          <p>
            <Button
              id="fillEmail"
              color="primary"
              variant="contained"
              disableElevation={true}
              onClick={onFillEmailClick}
            >
              {t("odeslat email")}
            </Button>
          </p>
        </div>
      </div>
    </>
  )
}
