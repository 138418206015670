import { alpha, Theme } from "@mui/material"
import { createStyles, makeStyles } from "@mui/styles"
import headerImage from "../../assets/header.png"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      backgroundImage: `url(${headerImage})`,
      backgroundSize: "auto 200%",
      backgroundPosition: "center 12%",
      backgroundRepeat: "no-repeat",
      backgroundColor: alpha(theme.palette.common.black, 1),
      [theme.breakpoints.up("sm")]: {
        backgroundPosition: "center",
      }
    },
    top: {
      height: "70px",
      display: "flex",
      alignItems: "center",
    },
    logo: {
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
    },
    main: {
      flexGrow: 1,
      display: "flex",
      paddingTop: "60px",
      paddingBottom: "40px",
      [theme.breakpoints.up("sm")]: {
        paddingTop: "100px",
        paddingBottom: "80px",
      },
      "& h1": {
        margin: "auto 0",
        display: "inline",
        padding: "12px",
      },
    },
  })
})

export default useStyles
