import {IFormNewApplication} from "../../../atoms/application"
import {Client} from "../../../generated-sources/sp/api"

export const createClient = (
  formNewApplication: IFormNewApplication
): Client => {
  const {
    name,
    surname,
    phone,
    email,
    company,
  } = formNewApplication
  return {
    name,
    surname,
    phone,
    email,
    company
  }
}
