/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react"
import MaterialTable from "material-table"
import { useTranslation } from "react-i18next"
import icons from "./icons"
import columns from "./columns"
import { useStyles } from "./index"
import { useListApplication } from "../../../hooks/useListApplication"
import { formatDate, getStatusTanslation } from "./utils"
import { IRow } from "./IRow"
import { useAdelErrors } from "../../../hooks/useAdelErrors"
import { useGetApplicationResult } from "../../../hooks/useGetApplicationResult"
import { useRecoilState } from "recoil"
import { listApplicationsState } from "../../../atoms/application"
import { AdelRequest } from "../../../generated-sources/sp"

const List: React.FC = () => {
  const [t] = useTranslation()
  const { extCaseId } = useAdelErrors()
  const tableRef = useRef<any>(null)
  const classes = useStyles()
  const { search } = useListApplication()
  const [userChangedStatus, setUserChangedStatus] = useState<
    Array<{ id?: number; status: string }>
  >([])
  const [listApplications] = useRecoilState(listApplicationsState)
  const { callApplicationResult } = useGetApplicationResult()

  // Initial load of
  useEffect(() => {
    search()
  }, [])

  // when occurred errors at adel, reload list
  // it is case when we redirected from adel and we want to see errors status at application in table
  useEffect(() => {
    if (extCaseId) {
      callApplicationResult(extCaseId).then(() => {
        search()
      })
    }
  }, [extCaseId])

  // when is reloaded application

  // map data from response of backend to client application
  const data: IRow[] = React.useMemo(() => {
    return listApplications.map((application: AdelRequest) => {
      return {
        client: application.client?.name + " " + application.client?.surname,
        company: application?.client?.company,
        idApplication: application.id,
        phone: application.client?.phone,
        date: formatDate(application.createdAt),
        status: getStatusTanslation(application.status),
        statusCode: application.status,
        employee: application.employee,
        branchOffice: application.branchOffice,
      }
    })
  }, [listApplications])

  const concatData = React.useMemo(() => {
    for (const change of userChangedStatus) {
      for (const row of data) {
        if (change.id === row.idApplication) {
          row.statusCode = change.status as "ERROR" | "DONE"
          row.status = getStatusTanslation(change.status)
        }
      }
    }
    return data
  }, [data, userChangedStatus])

  const arrColumns = useMemo(
    () => columns(t, setUserChangedStatus, tableRef?.current?.state),
    []
  )

  return (
    <div className={classes.root}>
      <MaterialTable
        tableRef={tableRef}
        title=""
        icons={icons}
        columns={arrColumns}
        data={concatData}
        components={{
          Container: ({ children }) => (
            <React.Fragment>{children}</React.Fragment>
          ),
        }}
        options={{
          sorting: true,
          searchFieldVariant: "outlined",
          emptyRowsWhenPaging: false,
          draggable: false,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: "žádné žádosti k nahlédnutí",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} z {count}",
            labelRowsSelect: "řádků",
            firstTooltip: "první strana",
            lastTooltip: "poslední strana",
            previousTooltip: "předchozí stránka",
            nextTooltip: "další strana",
          },
          toolbar: {
            searchPlaceholder: "hledat",
            searchTooltip: "hledat",
          },
        }}
      />
    </div>
  )
}

export default List
