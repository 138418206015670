import { atom } from "recoil"
import { sessionStorageEffect } from "./index"

enum Keys {
  TRACKING_ID = "TRACKING_ID",
  LOGGED_OUT = "LOGGED_OUT",
}

export const trackingState = atom({
  key: Keys.TRACKING_ID,
  default: "",
  effects_UNSTABLE: [sessionStorageEffect<string>(Keys.TRACKING_ID)],
})

export const loggedState = atom({
  key: Keys.LOGGED_OUT,
  default: false,
  effects_UNSTABLE: [sessionStorageEffect<boolean>(Keys.LOGGED_OUT)],
})
