import React from "react"
import useStyles from "./footer.styles"
import { Container } from "@mui/material"
import jsonPackage from "../../../package.json"
import { useIsAuthenticated } from "@azure/msal-react"
import { useNavigate } from "react-router"
import { Path } from "../../pages"

const Footer: React.FC = () => {
  const classes = useStyles()
  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate()

  // when bearer isn't set redirect to login page
  if (!isAuthenticated) {
    return null
  }

  return (
    <footer className={classes.root}>
      <Container>
        <p className={classes.copy}>© Deloitte v{jsonPackage.version}</p>
      </Container>
    </footer>
  )
}

export default Footer
