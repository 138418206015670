import React, { useEffect, useState } from "react"
import useStyles from "./moveTop.styles"
import { IconButton } from "@mui/material"
import { ExpandLess } from "@mui/icons-material"

const MoveTop: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const classes = useStyles()

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }
    window.addEventListener("scroll", handleScroll, { passive: true })
  }, [setIsVisible])

  const handleClick = React.useCallback(() => {
    window.scrollTo(0, 0)
  }, [])

  if (!isVisible) {
    return null
  }

  return (
    <IconButton className={classes.root} color="primary" onClick={handleClick}>
      <ExpandLess />
    </IconButton>
  )
}

export default MoveTop
