import consola from "consola"

const logger = consola.withDefaults({})

export const info = (message: any) => {
  logger.info(message)
}

export const error = (message: string) => {
  logger.error(message)
}
